<template>
  <v-card class="milestone--card" outlined>
    <v-tooltip :disabled="milestone.name.length <= 23" bottom>
      <template #activator="{ on, attrs }">
        <v-card-title
          class="milestone--card-header align-start pb-0"
          v-bind="attrs"
          v-on="on"
        >
          <span class="milestone--card-title text-h5 text-ellipsis">
            {{ milestone.name }}
          </span>
          <!-- <v-spacer /> -->
          <span class="milestone--card-price font-weight-bold">
            GBP £{{ milestone.price / 100 }}
          </span>
        </v-card-title>
      </template>
      <span>{{ milestone.name }}</span>
    </v-tooltip>

    <v-spacer class="milestone--card-spacer" />
    <v-card-text class="milestone--card-description pb-2 pt-0">
      {{ milestone.description }}
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-btn
        class="font-weight-bold"
        color="primary"
        @click="updateDialog = true"
        block
      >
        Update Milestone: {{ count + 1 }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="updateDialog" max-width="500" persistent>
      <v-card>
        <v-card-title> Update Milestone </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                label="Milestone Name"
                :error-messages="form.$getError('name')"
                :hide-details="!form.$hasError('name')"
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="form.description"
                label="Milestone Description"
                rows="3"
                :error-messages="form.$getError('description')"
                :hide-details="!form.$hasError('description')"
                auto-grow
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.product_id"
                label="Stripe product id"
                :error-messages="form.$getError('product_id')"
                :hide-details="!form.$hasError('product_id')"
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.product_price_id"
                label="Stripe product price id"
                :error-messages="form.$getError('product_price_id')"
                :hide-details="!form.$hasError('product_price_id')"
                clearable
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-row dense>
            <v-col>
              <v-btn
                color="error"
                :disabled="form.$busy"
                @click="cancelUpdate()"
                block
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                :loading="form.$busy"
                :disabled="!form.$busy && !form.$isDirty()"
                @click="continueMilestoneUpdate()"
                block
              >
                Update Milestone
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Form from '@/utils/form'

import { mapMutations, mapActions } from 'vuex'

export default {
  props: {
    milestone: {
      type: Object,
      require: true
    },

    count: Number
  },

  data() {
    return {
      updateDialog: false,

      form: new Form({})
    }
  },

  mounted() {
    this.setForm()
  },

  methods: {
    ...mapMutations({
      updateListItem: 'milestone/updateListItem'
    }),

    ...mapActions({
      updateMilestone: 'milestone/updateMilestone',
      showSnackbar: 'showSnackbar'
    }),

    setForm() {
      this.form = new Form({ ...this.milestone })
    },

    cancelUpdate() {
      this.updateDialog = false
      this.setForm()
    },

    async continueMilestoneUpdate() {
      this.form.$clearErrors()

      this.form.$busy = true

      try {
        const {
          data: { data }
        } = await this.updateMilestone(this.form)

        this.form.$refresh(data)

        setTimeout(() => {
          this.updateDialog = false
          this.form.$busy = false

          this.updateListItem(data)
          this.showSnackbar({ message: `${this.form.name} Updated!` })
        }, 800)
      } catch (error) {
        if (error?.response?.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }

        this.showSnackbar({
          color: 'error',
          message: error.response.data.message
        })

        this.form.$busy = false
      }
    }
  }
}
</script>

<style lang="scss">
.milestone {
  &--card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    $titleWidth: 70%;
    &-title {
      max-width: 70%;
      width: 100%;

      color: #252729;
      font-size: 18px;
      margin: 0;
    }

    &-price {
      width: 100% - $titleWidth;
      text-align: right;
      word-break: break-word;
    }

    &-description {
      line-height: 26px;
    }

    &-title,
    &-description {
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
    }
  }
}
</style>
